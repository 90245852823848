/* eslint-disable no-unused-expressions */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
/* eslint-disable import/order */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useContext, useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { Typography, Dialog, DialogContent } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import Button from '@material-ui/core/Button';
import { navigate } from 'gatsby-link';
import { Link } from 'gatsby';
import clsx from 'clsx';
import { myStyles } from '../signuppopup/styles';
import '../../styles.scss';
import Map from '../static-map/map';
import { AstContextDispatch } from '../../utils/ast-context';
import Helper from './helper';
import { themeStyles } from '../../styles';
import ThemeContext from '../../utils/theme-context';
import IconButton from '@material-ui/core/IconButton';
import { routerHandling } from './form-validation';
// import CloseIcon from '@material-ui/icons/Close';

export default function BuyLocation({ data, context }) {
  const classes = myStyles();
  const partnerTheme = useContext(ThemeContext);
  const globalThemeCss = themeStyles(partnerTheme);
  const dispatch = useContext(AstContextDispatch);
  const [helper, setHelper] = useState(false);
  const [nextDisabled, setNextDisabled] = useState(true);
  const [openPopup, setopenPopup] = useState(false);
  const [lead, setLead] = useState('');
  const [openBuyMsg, setOpenBuyMsg] = useState(false);
  const [openBothMsg, setOpenBothMsg] = useState(false);
  const [changeBuyLoc, setChangeBuyLoc] = useState(false);

  const getLeadDetails = () => {
    const getLead = JSON.parse(localStorage.getItem('lead'));
    setLead(getLead || {});
    return getLead;
  };

  useEffect(() => {
    if (performance.getEntriesByType('navigation')[0].type === 'navigate') {
      navigate('/ast');
    }
    const value = getLeadDetails();
    // console.log(value, 'getleadtDeatils value in buy location');
    setNextDisabled(!value?.buyingLocation?.selectedOption);
    // if (value?.buyingLocation?.state === 'IA') {
    //   // setopenPopup(!openPopup);
    //   // setChangeBuyLoc(!changeBuyLoc);
    // }
  }, []);

  const setPlace = (location) => {
    if (location?.state === 'IA' && lead?.sellingLocation?.state === 'IA') {
      setOpenBothMsg(true);
      setChangeBuyLoc(false);
    } else if (location?.state === 'IA') {
      if (lead.operationType === 'Buying') {
        setopenPopup(!openPopup);
        setOpenBuyMsg(false);
        setChangeBuyLoc(false);
      } else {
        setopenPopup(openPopup);
        setOpenBuyMsg(true);
        setChangeBuyLoc(false);
      }
      // setopenPopup(!openPopup);
      // setOpenBuyMsg(true);
      // setChangeBuyLoc(false);
    } else {
      setOpenBuyMsg(false);
      setOpenBothMsg(false);
      setopenPopup(openPopup);
    }
    // if (location?.state === 'IA') {
    //   setopenPopup(!openPopup);
    // }
    if (typeof location === 'object' && Object.keys(location).length > 0) {
      dispatch({
        type: 'SET_BUY_LOCATION',
        value: {
          ...lead,
          buyingLocation: {
            ...location
          }
        }
      });
      // console.log(lead, 'Leadvalue before close');
      // setNextDisabled(false);
      if (location?.state === 'IA') {
        setNextDisabled(true);
      } else {
        setNextDisabled(false);
      }
    } else {
      setNextDisabled(true);
    }
  };

  const handlerClick = (path) => {
    const value = getLeadDetails();
    if (value?.buyingLocation && value?.buyingLocation?.state === 'IA') {
      setOpenBuyMsg(true);
    } else {
      setOpenBuyMsg(false);
      navigate(path);
    }
  };

  const helperShow = () => {
    setHelper(true);
  };

  const helperHide = () => {
    setHelper(false);
  };

  const handleClose = () => {
    setopenPopup(!openPopup);
    setNextDisabled(true);
    setOpenBuyMsg(false);
    setOpenBothMsg(false);
    setChangeBuyLoc(true);
    dispatch({
      type: 'SET_BUY_LOCATION',
      value: {
        ...lead,
        buyingLocation: {
        }
      }
    });
    // console.log(lead, 'leadvalueafter close');
  };

  const handleCloseBoth = () => {
    setOpenBuyMsg(false);
    setOpenBothMsg(false);
    setChangeBuyLoc(true);
    setNextDisabled(true);
    dispatch({
      type: 'SET_BUY_LOCATION',
      value: {
        ...lead,
        buyingLocation: {
        }
      }
    });
    // console.log(lead, 'leadvalueafter both close');
  };

  const handleNext = () => {
    navigate('/ast/both/basic-realestateagent');
  };

  const handleBack = () => {
    const leadValue = getLeadDetails();
    // console.log(leadValue, 'leadValue in handle back');
    if (leadValue.sellingLocation.state === 'IA') {
      navigate('/ast/both/sell-location');
    }
  };
  const closeModal = () => {
    localStorage.removeItem('lead');
    localStorage.removeItem('rewards');
    localStorage.removeItem('mobile');
    localStorage.removeItem('client-details');
    localStorage.removeItem('mongoContactId');
    localStorage.removeItem('pageLength');
    localStorage.removeItem('utm');
    navigate('/');
  };

  return (
    <Container id="ast-buy-location" className={classes.root}>
      <Grid container>
        {helper && (
          <>
            <Helper helperData={data} contextData={context} helperSwitch={helperHide} />
          </>
        )}
        {!helper
          && (
            <>
              <div className={classes.buy}>
                <div className={classes.prev}>
                  {lead?.sellingLocation?.state === 'IA' ? (
                    <IconButton aria-label="back" onClick={handleBack} className={classes.backPage}>
                      <ArrowBackIcon />
                    </IconButton>
                  ) : (
                    <Link to={routerHandling(context, 'prev')}>
                      <IconButton aria-label="back" className={classes.backPage}>
                        <ArrowBackIcon />
                      </IconButton>
                    </Link>
                  )}
                  {data && data.showHelperText && (
                    <Typography variant="caption">
                      <div>
                        <Button onClick={helperShow} className={classes.haveQuestions}>
                          <ContactSupportIcon />
                          Have Questions
                        </Button>
                      </div>
                    </Typography>
                  )}
                  {/* <IconButton aria-label="close" className={classes.margin} onClick={closeModal}>
                <CloseIcon />
              </IconButton> */}
                </div>
                {/* <Grid spacing={3} container direction="column" justifyContent="center" alignItems="center"> */}
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Paper className={classes.paper}>
                    <Typography variant="h2" className={classes.internalContent}>{data.question.internal.content}</Typography>
                    {/* <Typography className={classes.internalSubText}>{data.helperText.internal.content}</Typography> */}
                  </Paper>
                </Grid>
                <Grid item xs={10} sm={10} md={6} lg={6}>
                  <Paper className="mainWrapper">
                    {lead && !changeBuyLoc
                      && (
                        <Map fromBuy value={lead?.buyingLocation?.selectedOption} place={setPlace} mapView="buy" />
                      )}
                    {changeBuyLoc
                      && (
                        // <Map fromBuy value={lead?.buyingLocation?.selectedOption} place={setPlace} mapView="buy" />
                        <Map fromBuy value={null} place={setPlace} mapView="buy" />
                      )}
                  </Paper>
                </Grid>
                {/* <Grid item xs={12} sm={12} md={12} lg={12}>
              <Paper className={clsx(classes.paper, classes.typoAlign, classes.customMarginTop)}>
                <Button disabled={nextDisabled} onClick={() => handlerClick(routerHandling(context, 'next'))} size="large" className={clsx(globalThemeCss.button, classes.nextButton)} variant="contained">Next</Button>
              </Paper>
            </Grid> */}
                {/* </Grid> */}
                {!openBuyMsg && !openBothMsg && !openPopup
                  && (
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Paper className={clsx(classes.paper, classes.typoAlign, classes.customMarginTop)}>
                        <Button id="next-btn" disabled={nextDisabled} onClick={() => handlerClick(routerHandling(context, 'next'))} size="large" className={clsx(globalThemeCss.containedBtn, classes.nextButton)} variant="contained">Next</Button>
                      </Paper>
                    </Grid>
                  )}
              </div>
              {openPopup
                && (
                  <Dialog
                    open={openPopup}
                    onClose={(_, reason) => { if (reason !== 'backdropClick') { handleClose; } }}
                    // aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    role="dialog"
                    aria-modal="true"
                    aria-label="Service unavailable"
                    PaperProps={{
                      style: {
                        width: 'auto',
                        height: 'auto',
                        textAlign: 'center'
                      }
                    }}
                  >
                    <DialogContent id="alert-dialog-description">
                      {/* <Grid className={classes.dialogClose}>
                        <IconButton aria-label="close" onClick={handleClose}>
                          <CloseIcon />
                        </IconButton>
                      </Grid> */}
                      <Grid container justify="center" alignItems="center">
                        <Typography>We noticed that you're searching for a home in Iowa.</Typography>
                      </Grid>
                      <Grid container justify="center" alignItems="center">
                        <Typography>
                          Service is unavailable in this state.
                        </Typography>
                      </Grid>
                      <Grid style={{ display: 'flex', justifyContent: 'center' }}>
                        <Button variant="contained" size="large" className={clsx(globalThemeCss.containedBtn, classes.nextButton)} onClick={handleClose}>
                          Close
                        </Button>
                      </Grid>
                    </DialogContent>
                  </Dialog>
                )}
              {openBuyMsg
                && (
                  <Dialog
                    open={openBuyMsg}
                    onClose={(_, reason) => { if (reason !== 'backdropClick') { handleCloseBoth; } }}
                    style={{ height: 'auto' }}
                    // aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    role="dialog"
                    aria-modal="true"
                    aria-label="Service unavailable"
                  >
                    <DialogContent id="alert-dialog-description">
                      <Grid container justify="center" alignItems="center">
                        <Typography style={{ textAlign: 'center' }}>We noticed that you're searching for a home in Iowa.</Typography>
                        <Typography>Service is unavaliable in this state.</Typography>
                        <Typography style={{ textAlign: 'center' }}>
                          We can still help you with the sale of your current home.
                        </Typography>
                      </Grid>
                      <Grid style={{ justifyContent: 'center', textAlign: 'center' }}>
                        <Button variant="contained" size="large" onClick={handleCloseBoth} className={clsx(globalThemeCss.containedBtn, classes.nextButton)}>
                          Change Buy Location
                        </Button>
                        <Button variant="contained" size="large" className={clsx(globalThemeCss.containedBtn, classes.nextButton)} onClick={handleNext}>
                          Next
                        </Button>
                      </Grid>
                    </DialogContent>
                  </Dialog>
                )}
              {openBothMsg
                && (
                  <Dialog
                    open={openBothMsg}
                    onClose={(_, reason) => { if (reason !== 'backdropClick') { handleCloseBoth; } }}
                    style={{ height: 'auto' }}
                    // aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    role="dialog"
                    aria-modal="true"
                    aria-label="Service unavailable"
                  >
                    <DialogContent id="alert-dialog-description">
                      <Grid container justify="center" alignItems="center">
                        <Typography style={{ textAlign: 'center' }}>We noticed that you're searching for a home in Iowa.</Typography>
                        <Typography>Service is unavaliable in this state.</Typography>
                      </Grid>
                      <Grid style={{ justifyContent: 'center', textAlign: 'center' }}>
                        <Button variant="contained" size="large" onClick={handleCloseBoth} className={clsx(globalThemeCss.containedBtn, classes.nextButton)}>
                          Change Buy Location
                        </Button>
                        <Button variant="contained" size="large" className={clsx(globalThemeCss.containedBtn, classes.nextButton)} onClick={closeModal}>
                          Exit
                        </Button>
                      </Grid>
                    </DialogContent>
                  </Dialog>
                )}
            </>
          )}
      </Grid>
    </Container>
  );
}
