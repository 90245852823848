/* eslint-disable object-curly-newline */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-nested-ternary */
import React, { useContext, useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Link } from 'gatsby';
import clsx from 'clsx';
import Container from '@material-ui/core/Container';
import { navigate } from 'gatsby-link';
import IconButton from '@material-ui/core/IconButton';
import { myStyles } from '../signuppopup/styles';
import { AstContextDispatch } from '../../utils/ast-context';
import { routerHandling } from './form-validation';
import Helper from './helper';

export default function BuySell({ data, context }) {
  const classes = myStyles();
  const dispatch = useContext(AstContextDispatch);
  const [lead, setLead] = useState({});
  const [isActive, setActive] = useState('');
  const [helper, setHelper] = useState(false);

  const helperShow = () => {
    setHelper(true);
  };

  const helperHide = () => {
    setHelper(false);
  };

  useEffect(() => {
    if (performance.getEntriesByType('navigation')[0].type === 'navigate') {
      navigate('/ast');
    }
    const getLead = JSON.parse(localStorage.getItem('lead'));
    setLead(getLead);
    if (getLead) {
      if (getLead?.operationType) {
        setActive(getLead.operationType);
        // SetIsEnabled(true);
      }
    }
  }, []);

  const changeColor = () => {
    setActive(!isActive);
  };

  const handleOperationType = (event, path) => {
    const { hasBuy, hasSell, label } = path;
    event.preventDefault();
    if (hasBuy && !hasSell) {
      navigate(`/ast/buy/${context.buyPages[context.pageIndex + 1].screenType.toLowerCase().replace(/\s/g, '')}`, {
        replace: true
      });
      localStorage.setItem('pageLength', context.buyPages.length);
      if (lead?.buyingLocation?.state === 'IA') {
        dispatch({ type: 'SET_BUY_SELL', value: { ...lead, operationType: label, buyingLocation: {} } });
      } else {
        dispatch({ type: 'SET_BUY_SELL', value: { ...lead, operationType: label } });
      }
    } else if (!hasBuy && hasSell) {
      navigate(`/ast/sell/${context.sellPages[context.pageIndex + 1].screenType.toLowerCase().replace(/\s/g, '')}`, {
        replace: true
      });
      localStorage.setItem('pageLength', context.sellPages.length);
      if (lead?.sellingLocation?.state === 'IA') {
        dispatch({ type: 'SET_BUY_SELL', value: { ...lead, operationType: label, sellingLocation: {} } });
      } else {
        dispatch({ type: 'SET_BUY_SELL', value: { ...lead, operationType: label } });
      }
    } else {
      navigate(`/ast/both/${context.bothPages[context.pageIndex + 1].screenType.toLowerCase().replace(/\s/g, '')}`, {
        replace: true
      });
      localStorage.setItem('pageLength', context.bothPages.length);
      if (lead?.sellingLocation?.state === 'IA' && lead?.buyingLocation?.state === 'IA') {
        dispatch({ type: 'SET_BUY_SELL', value: { ...lead, operationType: label, sellingLocation: {}, buyingLocation: {} } });
      } else {
        dispatch({ type: 'SET_BUY_SELL', value: { ...lead, operationType: label } });
      }
      dispatch({ type: 'SET_BUY_SELL', value: { ...lead, operationType: label } });
    }
  };

  // const closeModal = () => {
  //   navigate('/');
  //   localStorage.removeItem('lead');
  // };

  return (
    <>
      <Container id="ast-service-selection" className={clsx(classes.root, classes.astServiceSelection)}>
        <Grid container>
          {helper && (
            <>
              <Helper helperData={data} contextData={context} helperSwitch={helperHide} />
            </>
          )}
          {!helper
            && (
              <>
                <div className={classes.buy}>
                  <div className={classes.prev}>
                    <Link to={routerHandling(context, 'prev')}>
                      <IconButton aria-label="back" className={classes.backPage}>
                        <ArrowBackIcon />
                      </IconButton>
                    </Link>
                    {data && data.showHelperText && (
                      <Typography variant="caption">
                        <div>
                          <Button onClick={helperShow} className={classes.haveQuestions}>
                            <ContactSupportIcon />
                            Have Questions
                          </Button>
                        </div>
                      </Typography>
                    )}
                    {/* <IconButton aria-label="close" className={classes.margin} onClick={closeModal}>
                    <CloseIcon />
                  </IconButton> */}
                  </div>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Paper className={classes.paper}>
                      <Typography variant="h2" className={classes.internalContent}>{data.question.internal.content}</Typography>
                      {data.showHelperText ? <Typography className={classes.internalSubText}>{data.helperText.internal.content}</Typography> : ''}
                    </Paper>
                  </Grid>
                  <Grid container spacing={3} className={clsx(classes.gridFix, classes.customPaddingBtm)}>
                    {context.buySellPath.map((path, index) => (
                      <Grid item lg={4} md={4} sm={12} xs={12} key={index}>
                        <Button className={classes.buyingSellingClass} id={`service-selection-${path.label}`} onClick={(e) => handleOperationType(e, path)}>
                          <Card className={isActive === path.label ? clsx(classes.cardOnSelect) : clsx(classes.signIncard)} onClick={changeColor}>
                            <CardContent className={classes.cardContent}>
                              <img className={classes.buySellCardImg} src={`https:${path.image.file.url}`} alt={path?.image?.description} />
                              <Typography variant="h4" className={clsx(classes.cardText, classes.labelMargin)}>{path.label}</Typography>
                            </CardContent>
                          </Card>
                        </Button>
                      </Grid>
                    ))}
                  </Grid>
                </div>
              </>
            )}
        </Grid>
      </Container>
    </>
  );
}
