/* eslint-disable no-undef */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/no-unescaped-entities */
import React, { useContext, useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import { Typography, Container } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import InputAdornment from '@material-ui/core/InputAdornment';
import Grid from '@material-ui/core/Grid';
// import ToggleButton from '@material-ui/lab/ToggleButton';
// import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import clsx from 'clsx';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { INLINES } from '@contentful/rich-text-types';
import { navigate } from 'gatsby-link';
import Tooltip from '../tooltip';
import { AstContextDispatch } from '../../utils/ast-context';
import { myStyles } from '../signuppopup/styles';
import { routerHandling, useFormControls } from './form-validation';
// eslint-disable-next-line import/no-cycle
import Helper from './helper';
import { themeStyles } from '../../styles';
import ThemeContext from '../../utils/theme-context';
import { postOrUpdateContactInfo } from './submitcontactInfo';

// require('dotenv').config({
//   path: '.env'
//   path: '../../../.env'
// });

export default function ContactForm({ data, context }) {
  const [loading, setLoading] = useState(false);
  const classes = myStyles();
  const partnerTheme = useContext(ThemeContext);
  const globalThemeCss = themeStyles(partnerTheme);
  const [phoneNumberValue, setphoneNumberValue] = useState('');
  // const [textMeVisible, setTextMeVisible] = useState(false);
  // const [alignment, setAlignment] = React.useState('left');
  const [helper, setHelper] = useState(false);
  const {
    handleInputValue,
    formIsValid,
    errors,
    validate
  } = useFormControls();

  const dispatch = useContext(AstContextDispatch);

  const [customerInfo, setcustomerInfo] = useState({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    phonetype: 'Mobile',
    textYou: false,
    memberNumber: ''
  });

  const TextOptInLink = ({ children }) => (<a href={(JSON.parse(data ? data.optInToTextingText.raw : localStorage.getItem('mobile'))).content[0].content[1].data.uri} className={classes.consentText} target="_blank" rel="noreferrer">{children}</a>);
  const ConsentLink = ({ children }) => (children[0] === 'Terms of Use' ? <a href={(JSON.parse(context ? context.consentToCallText.raw : localStorage.getItem('rewards'))).content[0].content[1].data.uri} className={classes.consentText} target="_blank" rel="noreferrer">{children}</a> : <a href={(JSON.parse(context ? context.consentToCallText.raw : localStorage.getItem('rewards'))).content[0].content[3].data.uri} className={classes.consentText} target="_blank" rel="noreferrer">{children}</a>);

  const options1 = {
    renderNode: {
      [INLINES.HYPERLINK]: (node, children) => <ConsentLink>{children}</ConsentLink>
    }
  };

  const options2 = {
    renderNode: {
      [INLINES.HYPERLINK]: (node, children) => <TextOptInLink>{children}</TextOptInLink>
    }
  };

  // const setToggle = (event, value) => {
  //   setTextMeVisible(value === 'Mobile');
  //   setcustomerInfo({ ...customerInfo, phonetype: value });
  //   handleInputValue(event);
  // };

  // const handleAlignment = (event, newAlignment) => {
  //   setAlignment(newAlignment);
  // };

  const contactInfoStatus = 'incomplete';

  useEffect(() => {
    // eslint-disable-next-line func-names
    if (performance.getEntriesByType('navigation')[0].type === 'navigate') {
      window.location.reload();
    }
    window.onbeforeunload = function () {
      localStorage.removeItem('lead');
      localStorage.removeItem('rewards');
      localStorage.removeItem('mobile');
      localStorage.removeItem('client-details');
      localStorage.removeItem('mongoContactId');
      localStorage.removeItem('pageLength');
      localStorage.removeItem('utm');
    };

    const getLead = JSON.parse(localStorage.getItem('lead'));
    setphoneNumberValue(getLead?.customerInfo?.phoneNumber);
    // setLead(getLead || {});
    // if (getLead?.customerInfo?.phonetype) {
    //   setTextMeVisible(getLead?.customerInfo?.phonetype === 'Mobile');
    // }

    if (getLead?.customerInfo) {
      setcustomerInfo({ ...customerInfo, ...getLead.customerInfo });
      validate(getLead?.customerInfo);
    } else if (!localStorage.getItem('client-details')) {
      const jsonObject = {
        clientNumber: context ? context.metadata.clientNumber : null,
        clientName: context ? context.metadata.leadSource : null,
        partnerId: context ? context.appData.partnerId : null
      };
      localStorage.setItem('client-details', JSON.stringify(jsonObject));
      localStorage.setItem('rewards', context.consentToCallText.raw);
      localStorage.setItem('mobile', data.optInToTextingText.raw);
    }
  }, []);

  const handlerClick = async (path) => {
    setLoading(true);
    const getLead = JSON.parse(localStorage.getItem('lead'));
    dispatch({ type: 'SET_CUSTOMER_DETAILS', value: { ...getLead, customerInfo, memberNbrLabel: data.memberFieldLabel } });
    try {
      const endpoint = context.envVars.SAVE_AST_CUSTOMER_URL || `${process.env.GATSBY_AST_SAVE_CUSTOMER_ENDPOINT}`;
      const response = await postOrUpdateContactInfo(customerInfo, contactInfoStatus, endpoint);
      if (response && response.contactId) {
        localStorage.setItem('mongoContactId', response.contactId);
      }
    } catch (e) {
      setLoading(false);
      console.log('Error while saving customer info', e);
    }
    // setLoading(false);
    navigate(path);
  };
  const handlerClickForRep = () => {
    dispatch({ type: 'SET_OFF_RAMP_FLAG', value: { ...lead, isOffRamp: true } });
    navigate(routerHandling({}, 'next', true));
  };

  // const helperShow = () => {
  //   setHelper(true);
  // };

  const helperHide = () => {
    setHelper(false);
  };

  // const closeModal = () => {
  //   navigate('/');
  //   localStorage.removeItem('lead');
  //   localStorage.removeItem('rewards');
  //   localStorage.removeItem('mobile');
  //   localStorage.removeItem('client-details');
  //   localStorage.removeItem('mongoContactId');
  // };

  function handlePhoneNumber(event) {
    const phoneValue = event.target.value.replace(/\D/g, '');
    setcustomerInfo(
      { ...customerInfo, phoneNumber: phoneValue }
    );
    setphoneNumberValue(phoneValue);
  }

  useEffect(() => {
    if (phoneNumberValue?.length === 10) {
      const cleaned = (`${phoneNumberValue}`).replace(/\D/g, '');
      const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        setphoneNumberValue(`(${match[1]})-${match[2]}-${match[3]}`);
      }
    }
  }, [phoneNumberValue]);

  return (
    <Container id="ast-customer-info" className={clsx(classes.root, classes.relative)}>
      <Grid container>
        {helper && (
          <>
            <Helper helperData={data} contextData={context} helperSwitch={helperHide} />
          </>
        )}
        {!helper
        && (
          <>
            {context
            && (
              <div className={clsx(classes.prev, classes.customPlacement)}>
                {/* <Typography variant="caption">
                  <Button onClick={helperShow} className={classes.haveQuestions}>
                    <ContactSupportIcon />
                    Have Questions
                  </Button>
                </Typography> */}
                  {/* <IconButton aria-label="close" className={classes.margin} onClick={closeModal}>
                    <CloseIcon />
                  </IconButton> */}
              </div>
            )}
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Paper className={classes.paper}>
                {data ? (
                  <>
                    <Typography variant="h2" component="h2" className={classes.internalContent}>
                      {data.question.internal.content}
                    </Typography>
                    <Typography className={classes.internalSubText}>
                      {data.questionSubtext}
                    </Typography>
                  </>
                ) : (
                  <Typography variant="h2" component="h2" className={classes.internalContent}>
                    Let us know how we can reach you
                  </Typography>
                )}
              </Paper>
            </Grid>
            <form noValidate autoComplete="off">
              <Grid container>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Paper className={classes.paper}>
                    <TextField
                      id="ast-customer-info-fname"
                      label="First Name "
                      variant="outlined"
                      className={classes.inputField}
                      value={customerInfo.firstName}
                      required
                      name="firstName"
                      inputProps={{ maxlength: 50, autocomplete: 'given-name' }}
                      onKeyUp={handleInputValue}
                      onBlur={handleInputValue}
                      {...(errors.firstName && { error: true, helperText: errors.firstName })}
                      onChange={(event) => setcustomerInfo({ ...customerInfo, firstName: event.target.value })}
                    />
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Paper className={classes.paper}>
                    <TextField
                      id="ast-customer-info-lname"
                      label="Last Name "
                      variant="outlined"
                      value={customerInfo.lastName}
                      required
                      inputProps={{ maxlength: 50, autocomplete: 'family-name' }}
                      onKeyUp={handleInputValue}
                      onBlur={handleInputValue}
                      name="lastName"
                      {...(errors.lastName && { error: true, helperText: errors.lastName })}
                      onChange={(event) => setcustomerInfo({ ...customerInfo, lastName: event.target.value })}
                      className={classes.inputField}
                    />
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Paper className={classes.paper}>
                    <TextField
                      id="ast-customer-info-email"
                      label="Email "
                      variant="outlined"
                      value={customerInfo.email}
                      required
                      inputProps={{ autocomplete: 'email' }}
                      onKeyUp={handleInputValue}
                      onBlur={handleInputValue}
                      name="email"
                      {...(errors.email && { error: true, helperText: errors.email })}
                      onChange={(event) => setcustomerInfo({ ...customerInfo, email: event.target.value })}
                      className={classes.inputField}
                    />
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Paper className={classes.paper}>
                    <TextField
                      id="ast-customer-info-primary-number"
                      label="Phone Number "
                      variant="outlined"
                      InputProps={{ inputProps: { maxLength: 10, autocomplete: 'tel-national' } }}
                      onKeyUp={handleInputValue}
                      value={(phoneNumberValue)}
                      required
                      onBlur={handleInputValue}
                      name="phoneNumber"
                      {...(errors.phoneNumber && { error: true, helperText: errors.phoneNumber })}
                      onChange={(event) => {
                        handlePhoneNumber(event);
                      }}
                      className={classes.inputField}
                    />
                  </Paper>
                </Grid>
                {/* <Grid item xs={12} sm={6} md={6} lg={6}>
                  <Paper className={classes.paper}>
                    <ToggleButtonGroup
                      value={customerInfo.phonetype}
                      exclusive
                      onChange={setToggle}
                      aria-label="Toggle Button Group"
                    >
                      <ToggleButton
                        value="Mobile"
                        aria-label="Mobile"
                        className={classes.toggleButton}
                        classes={{ selected: classes.toggleSelected }}
                        id="ast-customer-info-mobile"
                      >
                        Mobile
                      </ToggleButton>
                      <ToggleButton
                        value="Home"
                        aria-label="Home"
                        className={classes.toggleButton}
                        classes={{ selected: classes.toggleSelected }}
                        id="ast-customer-info-home"
                      >
                        Home
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Paper>
                </Grid> */}
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Paper className={classes.paper}>
                    <Typography className={classes.optText}>
                      <Checkbox
                        className={classes.themeSecondaryColor}
                        checked={customerInfo.textYou}
                        onChange={() => setcustomerInfo({ ...customerInfo, textYou: !customerInfo.textYou })}
                        id="ast-customer-info-checkbox"
                      />
                      <label htmlFor="ast-customer-info-checkbox">
                        {documentToReactComponents(JSON.parse(data ? data.optInToTextingText.raw : localStorage.getItem('mobile')), options2)}
                      </label>
                    </Typography>
                  </Paper>
                </Grid>
                {data && data.showMemberField && (
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Paper className={classes.paper}>
                      <TextField
                        id="ast-customer-info-member-number"
                        label={data.memberFieldLabel}
                        variant="outlined"
                        value={customerInfo.memberNumber}
                        onKeyUp={handleInputValue}
                        name="memberNumber"
                        onChange={(event) => setcustomerInfo({ ...customerInfo, memberNumber: event.target.value })}
                        className={classes.inputField}
                        inputProps={{
                          maxLength: 18
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment className={classes.tooltipwrap} position="end">
                              <Tooltip
                                ariaLabel={`${data.memberFieldLabel} Additional Information`}
                                description={documentToReactComponents(JSON.parse(data.memberFieldHelperText.raw))}
                                placement="top"
                                name="ssa"
                              />
                            </InputAdornment>
                          )
                        }}
                        {...(errors.memberNumber && { error: true, helperText: `Please enter a valid ${data.memberFieldLabel}` })}
                      />
                    </Paper>
                  </Grid>
                )}
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Paper className={clsx(classes.paper, classes.typoAlign)}>
                    {/* <Button size="large" className={clsx(globalThemeCss.textBtn, classes.nextButton)} variant="text">
                      Text
                    </Button>
                    <Button size="large" className={clsx(globalThemeCss.containedBtn, classes.nextButton)} variant="contained">
                      Contained
                    </Button>
                    <Button size="large" className={clsx(globalThemeCss.outlinedBtn, classes.nextButton)} variant="outlined">
                      Outlined
                    </Button> */}
                    <Button size="large" id="ast-customer-info-next-btn" className={clsx(formIsValid(customerInfo) && globalThemeCss.containedBtn, classes.nextButton)} disabled={!formIsValid(customerInfo) || loading} onClick={() => { context ? handlerClick(routerHandling(context, 'next')) : handlerClickForRep(); }} variant="contained">
                      {context ? (
                        <>
                          {loading ? (
                            'Loading...'
                          ) : (
                            'Next'
                          )}
                        </>
                      ) : (
                        'Submit'
                      )}
                    </Button>
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Paper className={classes.paper}>
                    <Typography className={classes.consentText}>
                      {documentToReactComponents(JSON.parse(context ? context.consentToCallText.raw : localStorage.getItem('rewards')), options1)}
                    </Typography>
                  </Paper>
                </Grid>
              </Grid>
            </form>
          </>
        )}
      </Grid>
    </Container>
  );
}
