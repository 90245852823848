/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useContext, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import clsx from 'clsx';
import { Link } from 'gatsby';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
// import CloseIcon from '@material-ui/icons/Close';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import { Typography } from '@material-ui/core';
import { navigate } from 'gatsby-link';
import IconButton from '@material-ui/core/IconButton';
import { themeStyles } from '../../styles';
import { myStyles } from '../signuppopup/styles';
import { AstContextDispatch } from '../../utils/ast-context';
import Helper from './helper';
import ThemeContext from '../../utils/theme-context';
import { routerHandling } from './form-validation';

export default function MortgageApproval({ data, context }) {
  const classes = myStyles();
  const partnerTheme = useContext(ThemeContext);
  const globalThemeCss = themeStyles(partnerTheme);
  const dispatch = useContext(AstContextDispatch);
  const [helper, setHelper] = useState(false);
  const [lead, setLead] = useState({});

  useEffect(() => {
    // window.onbeforeunload = function () {
    //   localStorage.removeItem('lead');
    // };
    if (performance.getEntriesByType('navigation')[0].type === 'navigate') {
      navigate('/ast');
    }
    const getLead = JSON.parse(localStorage.getItem('lead'));
    setLead(getLead);
  }, []);

  const handleClickYes = (path) => {
    dispatch({ type: 'SET_MORTGAGE_PREAPPROVAL', value: { ...lead, isPreapproved: true } });
    navigate(path);
  };

  const handleClickNo = (path) => {
    dispatch({
      type: 'SET_MORTGAGE_PREAPPROVAL',
      value: { ...lead, isPreapproved: false }
    });
    navigate(path);
  };

  const helperShow = () => {
    setHelper(true);
  };

  const helperHide = () => {
    setHelper(false);
  };

  // const closeModal = () => {
  //   navigate('/');
  //   localStorage.removeItem('lead');
  // };

  return (
    <Container id="mortgage-approval" className={classes.root}>
      <Grid container>
        {helper && (
          <>
            <Helper helperData={data} contextData={context} helperSwitch={helperHide} />
          </>
        )}
        {!helper && (
          <>
            <div className={classes.buy}>
              <div className={classes.prev}>
                <Link to={routerHandling(context, 'prev')}>
                  <IconButton aria-label="back" className={classes.backPage}>
                    <ArrowBackIcon />
                  </IconButton>
                </Link>
                {data && data.showHelperText && (
                <Typography variant="caption">
                  <div>
                    <Button onClick={helperShow} className={classes.haveQuestions}>
                      <ContactSupportIcon />
                      Have Questions
                    </Button>
                  </div>
                </Typography>
                )}
                {/* <IconButton aria-label="close" className={classes.margin} onClick={closeModal}>
                  <CloseIcon />
                </IconButton> */}
              </div>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Paper className={classes.paper}>
                  <Typography variant="h2" className={classes.internalContent}>{data.question.internal.content}</Typography>
                  {/* <Typography className={classes.internalSubText}>{data.helperText.internal.content}</Typography> */}
                </Paper>
              </Grid>
              <Grid spacing={3} container direction="column" justifyContent="center" alignItems="center" className={clsx(classes.yesNo, classes.customPaddingBtm)}>
                <Grid item>
                  <Button
                    id="mortgage-approval-yes-btn"
                    onClick={() => handleClickYes(
                      routerHandling(context, 'next')
                    )}
                    variant="contained"
                    size="large"
                    className={clsx(globalThemeCss.containedBtn, classes.yesNoButton, classes.nextButton, typeof lead?.isPreapproved !== 'undefined' ? (lead?.isPreapproved ? classes.boxShadow : '') : '')}
                  >
                    Yes
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    id="mortgage-approval-no-btn"
                    onClick={() => handleClickNo(
                      routerHandling(context, 'next')
                    )}
                    variant="contained"
                    size="large"
                    className={clsx(globalThemeCss.containedBtn, classes.yesNoButton, classes.nextButton, typeof lead?.isPreapproved !== 'undefined' ? (lead?.isPreapproved ? '' : classes.boxShadow) : '')}
                  >
                    No
                  </Button>
                </Grid>
              </Grid>
            </div>
          </>
        )}
      </Grid>
    </Container>
  );
}
