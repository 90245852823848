/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';

export const postOrUpdateContactInfo = async (values, status, url) => {
  const clientDets = JSON.parse(localStorage.getItem('client-details'));
  const clientNumberValue = clientDets ? clientDets.clientNumber : null;
  const clientNameValue = clientDets ? clientDets.clientName : null;
  const contactId = localStorage.getItem('mongoContactId');
  const customerInfoSubmit = {
    status,
    firstName: values.firstName,
    lastName: values.lastName,
    email: values.email,
    phoneType: 'Mobile',
    primaryPhoneNo: values.phoneNumber,
    textOptIn: values.textYou,
    clientNumber: clientNumberValue,
    clientName: clientNameValue,
    contactId: contactId || ''
  };
  // eslint-disable-next-line no-useless-catch
  try {
    const headers = {};
    const apiCall = await axios.post(
      url,
      customerInfoSubmit,
      headers,
      true
    );
    return apiCall.data;
  } catch (err) {
    throw err;
  }
};
