/* eslint-disable radix */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useContext, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import clsx from 'clsx';
import { Link } from 'gatsby';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CloseIcon from '@material-ui/icons/Close';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import { Typography } from '@material-ui/core';
import { navigate } from 'gatsby-link';
import IconButton from '@material-ui/core/IconButton';
import { AstContextDispatch } from '../../utils/ast-context';
import { themeStyles } from '../../styles';
import { myStyles } from '../signuppopup/styles';
import Helper from './helper';
import ThankYou from './thank-you';
import ThemeContext from '../../utils/theme-context';
import { routerHandling } from './form-validation';

export default function RealEstateAgent({ data, context }) {
  const classes = myStyles();
  const partnerTheme = useContext(ThemeContext);
  const globalThemeCss = themeStyles(partnerTheme);
  const dispatch = useContext(AstContextDispatch);
  const [helper, setHelper] = useState(false);
  const [showThanks, setShowThanks] = useState(false);
  const [lead, setLead] = useState({});

  const getLeadDetails = () => {
    const getLead = JSON.parse(localStorage.getItem('lead'));
    setLead(getLead || {});
    return getLead;
  };
  useEffect(() => {
    // const getLead = JSON.parse(localStorage.getItem('lead'));
    // setLead(getLead);
    if (performance.getEntriesByType('navigation')[0].type === 'navigate') {
      navigate('/ast');
    }
    getLeadDetails();
  }, []);
  console.log("lead from realestate",lead);

  const handlerClick = (newLead, fromSubmit) => {
    let flag = false;
    let reason = [];
    // if (lead?.buyingLocation?.selectedOption === 'Key West, FL, USA' || lead?.buyingLocation?.selectedOption === 'Waterbury, CT, USA') {
    //   flag = true;
    //   reason = 'NO_BUY_BROKERS';
    // } else if (lead?.sellingLocation?.selectedOption.includes('Key West') || lead?.sellingLocation?.selectedOption.includes('Waterbury')) {
    //   flag = true;
    //   reason = 'NO_SELL_BROKERS';
    // } else if (['02720'].includes(lead?.buyingLocation?.zip)) {
    //   flag = true;
    //   reason = 'BUY_BROKERS_NO_AGENTS';
    // } else if (['02720'].includes(lead?.sellingLocation?.zip)) {
    //   flag = true;
    //   reason = 'SELL_BROKERS_NO_AGENTS';
    // } else
    if (fromSubmit === 'Yes') {
      flag = true;
      reason.push('REAL_ESTATE_AGENT');
    } 
    if (context.rewardTable?.childrenContentfulRewardTableRewardTiersJsonNode[0]?.max === parseInt(lead.buyMaxPrice) && context.rewardTable?.childrenContentfulRewardTableRewardTiersJsonNode[0]?.min === parseInt(lead.buyMinPrice)) {
      flag = true;
      reason.push('BUY_PRICE_OUT_OF_BOUND');
    }
    if (lead.isOffRamp && lead.buyMoveTimeframe=="6+ Months") {
      flag= true;
      reason.push('MOVE_TIMEFRAME_BUY')
    }
    if (lead.isOffRamp && lead.sellMoveTimeframe=="12+ Months") {
      flag= true;
      reason.push('MOVE_TIMEFRAME_SELL')
    }
    if (flag) {
      dispatch({
        type: 'SET_OFF_RAMP_FLAG',
        value: {
          ...newLead, isOffRamp: true, offrampReason: reason
        }
      });
      navigate(routerHandling({}, 'next', true));
      // navigate(routerHandling(context, 'next'));
    } else {
      navigate(routerHandling(context, 'next'));
      // const requestOptions = {
      //   method: 'POST',
      //   headers: { 'Content-Type': 'application/json' },
      //   body: newLead
      // };

      // fetch(
      //   `${process.env.GATSBY_SUBMIT_LEAD}`,
      //   requestOptions
      // ).then((response) => {
      //   setShowThanks(true);
      // });
    }
  };

  const handleYes = () => {
    dispatch({
      type: 'SET_REAL_ESTATE_AGENT',
      value: {
        ...lead, hasAgent: true
      }
    });
    const newLead = JSON.parse(localStorage.getItem('lead'));
    handlerClick(newLead, 'Yes');
  };

  const handleNo = () => {
    dispatch({
      type: 'SET_REAL_ESTATE_AGENT',
      value: {
        ...lead, hasAgent: false
      }
    });
    const newLead = JSON.parse(localStorage.getItem('lead'));
    handlerClick(newLead, 'No');
  };

  const helperShow = () => {
    setHelper(true);
  };

  const helperHide = () => {
    setHelper(false);
  };

  const closeModal = () => {
    navigate('/');
    localStorage.removeItem('lead');
  };

  const handlePrev = () => {
    if (lead?.buyingLocation?.state === 'IA') {
      return ('/ast/both/buy-location');
    }
    return routerHandling(context, 'prev');
  };

  return (
    <Container id="real-estate-agent" className={classes.root}>
      <Grid container>
        {helper && !showThanks && (
          <>
            <Helper helperData={data} contextData={context} helperSwitch={helperHide} />
          </>
        )}
        {!helper && !showThanks && (
          <>
            <div className={classes.root}>
              <div className={classes.prev}>
                <Link to={handlePrev()}>
                  <IconButton aria-label="back" className={classes.backPage}>
                    <ArrowBackIcon />
                  </IconButton>
                </Link>
                {data && data.showHelperText && (
                  <Typography variant="caption">
                    <div>
                      <Button onClick={helperShow} className={classes.haveQuestions}>
                        <ContactSupportIcon />
                        Have Questions
                      </Button>
                    </div>
                  </Typography>
                )}
                {/* <IconButton aria-label="close" className={classes.margin} onClick={closeModal}>
                  <CloseIcon />
                </IconButton> */}
              </div>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Paper className={classes.paper}>
                  <Typography variant="h2" className={classes.internalContent}>{data.question.internal.content}</Typography>
                </Paper>
              </Grid>
              <Grid spacing={3} container direction="column" justifyContent="center" alignItems="center" className={clsx(classes.yesNo, classes.customPaddingBtm, classes.removeMargin)}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Button
                    id="real-estate-agent-yes-btn"
                    onClick={handleYes}
                    variant="contained"
                    size="large"
                    className={clsx(globalThemeCss.containedBtn, classes.yesNoButton, classes.nextButton, typeof lead?.hasAgent !== 'undefined' ? (lead?.hasAgent ? classes.boxShadow : '') : '')}
                  >
                    Yes
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    id="real-estate-agent-no-btn"
                    onClick={handleNo}
                    variant="contained"
                    size="large"
                    className={clsx(globalThemeCss.containedBtn, classes.yesNoButton, classes.nextButton, typeof lead?.hasAgent !== 'undefined' ? (lead?.hasAgent ? '' : classes.boxShadow) : '')}
                  >
                    No
                  </Button>
                </Grid>
              </Grid>
            </div>
          </>
        )}
        {showThanks && (
          <ThankYou thanksData={context.thankYouScreen} />
        )}
      </Grid>
    </Container>
  );
}
