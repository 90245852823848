/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable arrow-body-style */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useContext, useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import clsx from 'clsx';
import axios from 'axios';
import Container from '@material-ui/core/Container';
import { Typography } from '@material-ui/core';
import { navigate } from 'gatsby-link';
import Link from '@material-ui/core/Link';
import { themeStyles } from '../../styles';
import { myStyles } from '../signuppopup/styles';
import ThemeContext from '../../utils/theme-context';
import Loading from './loading';
import { RichText } from '../RichText';
import { constructPayloadForASTSubmitLead } from './submitASTForm';
import { postOrUpdateContactInfo } from './submitcontactInfo';
import SEO from '../seo-component/seo';
import DefaultProfileImage from '../../images/default-agent-image.png';

export default function AstThankYou({ data, context }) {
  const classes = myStyles();
  const themeClass = themeStyles();
  const partnerTheme = useContext(ThemeContext);
  const globalThemeCss = themeStyles(partnerTheme);
  const [lead, setLead] = useState({});
  const [thanksData, setThanksData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [leadBroker, setLeadBroker] = useState(null);
  const endpoint = context.envVars.AST_FORM_SUBMIT_URL || `${process.env.GATSBY_AST_SUBMIT_LEAD_ENDPOINT}`;
  const contactInfoStatus = 'complete';
  const submitAstForm = async () => {
    const lead = JSON.parse(localStorage.getItem('lead'));
    const clientDetails = JSON.parse(localStorage.getItem('client-details'));
    const data = constructPayloadForASTSubmitLead(lead, clientDetails);
    try {
      await axios.post(endpoint, data, {
        headers: {
          'Content-Type': 'text/plain'
        }
      });
      try {
        const endpointASTCust = context.envVars.SAVE_AST_CUSTOMER_URL || `${process.env.GATSBY_AST_SAVE_CUSTOMER_ENDPOINT}`;
        await postOrUpdateContactInfo(lead.customerInfo, contactInfoStatus, endpointASTCust);
      } catch (err) {
        console.log('Error while updating status of customer info', err);
      }
      setLoading(false);
    } catch (err) {
      navigate('/ast-error');
    }
  };

  useEffect(() => {
    if (performance.getEntriesByType('navigation')[0].type === 'navigate') {
      navigate('/ast');
    }
    loading && submitAstForm();
  }, [loading]);

  useEffect(() => {
    window.addEventListener('popstate', () => {
      localStorage.removeItem('lead');
      navigate('/ast');
    });
    const getLead = JSON.parse(localStorage.getItem('lead'));
    setLead(getLead, context, data);
    if (lead) {
      let temp;
      let leadCopy;
      if (lead.isOffRamp) {
        temp = context.astOfframpThankYouScreen;
      } else if (lead.operationType === 'Buying' || lead?.sellingLocation?.state === 'IA') {
        temp = context.astBuyThankYouScreen;
      } else if (lead.operationType === 'Selling' || lead?.buyingLocation?.state === 'IA') {
        temp = context.astSellThankYouScreen;
      } else if (lead.operationType === 'Both' && lead?.sellingLocation?.state !== 'IA' && lead?.buyingLocation?.state !== 'IA') {
        temp = context.astBothThankYouScreen;
      }
      if (lead?.broker) {
        leadCopy = lead;
      }
      setLeadBroker(leadCopy);
      setThanksData(temp);
      return () => {
        localStorage.removeItem('lead');
        localStorage.removeItem('rewards');
        localStorage.removeItem('mobile');
        localStorage.removeItem('client-details');
        localStorage.removeItem('mongoContactId');
        localStorage.removeItem('pageLength');
        localStorage.removeItem('utm');
      };
    }
  }, [JSON.stringify(lead)]);

  const closeModal = () => {
    localStorage.removeItem('lead');
    localStorage.removeItem('rewards');
    localStorage.removeItem('mobile');
    localStorage.removeItem('client-details');
    localStorage.removeItem('mongoContactId');
    localStorage.removeItem('pageLength');
    localStorage.removeItem('utm');
    navigate('/');
  };
  const additionalCtAUrl = () => {
    window.open(thanksData.additionalCtAs[0].url);
  };

  const additionalCtAUrlTwo = () => {
    window.open(thanksData.additionalCtAs[0].externalUrlTwo);
  };

  return (
    <>
      {loading ? (
        <Loading description="Submitting your request..." />
      ) : (
        <Container id="ast-thankyou" className={classes.root}>
          <SEO title="Thank you for connect with us!" />
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            className={classes.yesNo}
          >
            <Grid item className={classes.thankYouHeading}>
              <Typography variant="h4">
                <RichText data={{ richText: thanksData?.title }} />
              </Typography>
            </Grid>
            {((thanksData.displayAgentProfilePictures === 'Yes') && (
              <Grid item className={classes.thankYou}>
                <Grid className="img-badge-wrapper" style={{ padding: '2em' }}>
                  <Grid className="profile-img-wrapper">
                    {leadBroker && leadBroker?.broker && Object.keys(leadBroker.broker).map((data, index) => {
                      return (<img className={`profile-img ${classes.thankYouProfileImg}`} src={leadBroker.broker[data].photo || DefaultProfileImage} alt={leadBroker.broker[data].agentFName + ' ' + leadBroker.broker[data].agentLName} />);
                    })}
                  </Grid>
                </Grid>
              </Grid>
            ))}
            {thanksData.displayAgentProfilePictures === 'Display Icon Alternative'
            && (
              <Grid item className={classes.thankYou}>
                <Grid className="img-badge-wrapper" style={{ padding: '2em' }}>
                  <Grid className="profile-img-wrapper">
                    <img className="profile-img" src={thanksData.iconAlternative.file.url} alt="Profile pic" />
                  </Grid>
                </Grid>
              </Grid>
            )}
            <Grid item className={clsx(classes.thankYou, classes.thankYouBodyText)}>
              <Typography variant="body2">
                <RichText data={{ richText: thanksData?.bodyText }} />
                {/* {Object.keys(lead.broker).map((name) => {
                    return (
                        '-' + (lead.broker[name].agentFName + lead.broker[name].agentLName)
                    )
                })} */}
              </Typography>
            </Grid>

            <Grid item xs={6} sm={6} md={6} lg={12} className={classes.thankYou}>
              {thanksData.showCloseButton
              && (
                <Button size="large" id="ast-thankyou-close-btn" onClick={closeModal} variant="contained" className={clsx(globalThemeCss.containedBtn, classes.nextButton)}>
                  {thanksData.closeButtonLabel || 'Close'}
                </Button>
              )}
              {(thanksData?.additionalCtAs && thanksData?.additionalCtAs[0]?.displayLabelOne)
              && (
                <>
                  {thanksData?.additionalCtAs[0]?.labelOneShouldBeDisplayedAs === 'Button' ? (
                    <Button size="large" id="ast-thankyou-cta1-btn" onClick={additionalCtAUrl} variant="contained" className={clsx(globalThemeCss.containedBtn, classes.nextButton)}>
                      {thanksData.additionalCtAs[0].label}
                    </Button>
                  ) : (
                    <Link component="button" id="ast-thankyou-cta1-link" onClick={additionalCtAUrl} className={classes.link}>
                      {thanksData.additionalCtAs[0].label}
                    </Link>
                  )}
                </>
              )}
              {(thanksData?.additionalCtAs && thanksData?.additionalCtAs[0]?.displayLabelTwo)
              && (
                <>
                  {thanksData?.additionalCtAs[0]?.labelTwoShouldBeDisplayedAs === 'Button' ? (
                    <Button size="large" id="ast-thankyou-cta2-btn" onClick={additionalCtAUrlTwo} variant="contained" className={clsx(globalThemeCss.containedBtn, classes.nextButton)}>
                      {thanksData.additionalCtAs[0].labelTwo}
                    </Button>
                  ) : (
                    <Link component="button" id="ast-thankyou-cta2-link" onClick={additionalCtAUrlTwo} className={classes.link}>
                      {thanksData.additionalCtAs[0].labelTwo}
                    </Link>
                  )}
                </>
              )}
            </Grid>
          </Grid>
        </Container>
      )}
    </>
  );
}
