/* eslint-disable object-curly-newline */
/* eslint-disable radix */
/* eslint-disable consistent-return */
/* eslint-disable no-undef */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-named-as-default */
/* eslint-disable import/named */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable no-plusplus */
/* eslint-disable max-len */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useContext, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import { Link } from "gatsby";
import Paper from "@material-ui/core/Paper";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Typography } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import clsx from "clsx";
import { navigate } from "gatsby-link";
import IconButton from "@material-ui/core/IconButton";
import { myStyles } from "../signuppopup/styles";
import { themeStyles } from "../../styles";
import { AstContext, AstContextDispatch } from "../../utils/ast-context";
import ThemeContext from "../../utils/theme-context";
import { routerHandling } from "./form-validation";

export default function BuyTimeframe({ data, context }) {
  const classes = myStyles();
  const astContext = useContext(AstContext);
  const dispatch = useContext(AstContextDispatch);
  const [timeline,setTimeline] = useState('')
  const [nextDisable, setNextDisable] = useState(true);

  const partnerTheme = useContext(ThemeContext);
  const globalThemeCss = themeStyles(partnerTheme);
  const [lead, setLead] = useState({});
  useEffect(() => {
    if (performance.getEntriesByType("navigation")[0].type === "navigate") {
      navigate("/ast");
    }
    const getLead = JSON.parse(localStorage.getItem("lead"));
    setLead(getLead);
    getLead?.buyMoveTimeframe && setTimeline(getLead?.buyMoveTimeframe)
    
  }, []);
  const handlerClick = (path) => {
    let reason = []
    const value = {
      ...lead,      
      buyMoveTimeframe:timeline
    }
    if(Array.isArray(lead?.offrampReason)){
      reason.push(...lead?.offrampReason)
    }
    if(timeline == "6+ Months"){
      reason.push("MOVE_TIMEFRAME_BUY")
      dispatch({
        type: "SET_OFF_RAMP_FLAG",
        value: {
          ...value,
          isOffRamp:true,
          offrampReason:reason,
        },
      });
    }else{
      dispatch({
        type: "SET_BUY_TIMEFRAME",
        value: value
        },
      );
    }  
    // if (lead?.buyingLocation?.selectedOption === "Key West, FL, USA" || ["02720"].includes(lead?.buyingLocation?.zip)) {
    //   navigate('/signup/buy/basic-realestateagent')
    // }
    navigate(path);
  };

  const updateMinValue = (value) => {
    setTimeline(value)
    setNextDisable(false);
  };
  return (
    <Container id="buy-price-range" className={classes.root}>
      <Grid container>
        <div className={classes.root}>
          <div className={classes.prev}>
            <Link to={routerHandling(context, "prev")}>
              <IconButton aria-label="back" className={classes.backPage}>
                <ArrowBackIcon />
              </IconButton>
            </Link>
          </div>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Paper className={classes.paper}>
              <Typography variant="h2" className={classes.internalContent}>
                {data.question.internal.content}
              </Typography>
            </Paper>
          </Grid>
          <Grid container spacing={3} className={classes.amount}>
            <Grid item xs={12} sm={4} md={4} lg={3}>
              <TextField
                id="outlined-select-currency-native"
                select
                className={classes.inputField}
                label="Est. Timeline"
                value={timeline}
                onChange={(event) => setTimeline(event.target.value)}
                SelectProps={{
                  native: true,
                }}
                variant="outlined"
              >
                <option></option>
                <option>Immediately</option>
                <option>1-3 Months</option>
                <option>3-6 Months</option>
                <option>6+ Months</option>
              </TextField>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Paper
              className={clsx(classes.paper, classes.typoAlign)}
              style={{ padding: "0px" }}
            >
              <Button
                id="buy-price-range-next-btn"
                disabled={!timeline}
                onClick={() => handlerClick(routerHandling(context, "next"))}
                variant="contained"
                size="large"
                className={clsx(
                  timeline ? globalThemeCss.containedBtn : "",
                  classes.nextButton
                )}
              >
                Next
              </Button>
            </Paper>
          </Grid>
        </div>
      </Grid>
    </Container>
  );
}
